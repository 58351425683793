<template>
  <b-modal
    centered
    modal-class="dns-records-modal "
    id="dns_records_modal"
    hide-footer
    hide-header
  >
    <div class="w-full h-full">
      <div @click="close()" class="float-right w-6 h-6 flex justify-end">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-3 h-3 cursor-pointer"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z"
            fill="black"
          />
        </svg>
      </div>
      <div
        class=" bg-white w-full h-full bg-opacity-80 rounded-lg"
      >
        <div class="flex flex-col justify-between h-full gap-10">
          <div class="flex flex-col flex-grow h-full border-[#ECEEF5]">
            <!-- heading-->
            <div>
              <div class="flex px-3 pb-[1.71rem] justify-between items-center">
                <div class="w-full flex gap-x-2"></div>
                <div class="w-full flex justify-center items-center">
                  <p
                    class="text-[#3C4549] mr-1 text-lg font-semibold font-poppins leading-none"
                  >
                    DNS Record List
                  </p>
                </div>
                <div
                  class="w-full flex items-center justify-end space-x-2"
                ></div>
              </div>
            </div>
            <!-- child-->
            <table class=" text-left flex-1 h-full">
              <thead
                :class="'bg-[#F4F6FA]'"
                class="text-[#757A8A] font-semibold text-[0.875rem] font-poppins"
              >
              <tr>
                <td class="py-3 text-left w-1/4 pl-10">Record Type</td>
                <td class="py-3 text-center w-1/4 px-10">Record Class</td>
                <td class="py-3 text-center w-1/4 px-10">Host Name (Or Name)</td>
                <td class="py-3 text-center w-1/4 px-10">Points to (or Address)</td>
              </tr>
              </thead>
              <tbody>
              <tr v-if="getCustomDomainsLoader.get_records">
                <td colspan="4">
                  <div class="p-5 text-center">
                    <clip-loader
                      :size="'26px'"
                      :color="'#1d4ed8'"
                    ></clip-loader>
                  </div>
                </td>
              </tr>
              <tr v-else-if="!getCustomDomainsLoader.get_records &&
                  getDNSRecords.length === 0
                ">
                <td colspan="4" class="">
                  <p
                    class="no-rows text-[#3C4549] text-[0.875rem] font-poppins"
                  >
                    <i class="fab fa-bandcamp" aria-hidden="true"></i>

                    No DNS records found.
                  </p>
                </td>
              </tr>
              <tr
                v-for="(item, index) in getDNSRecords"
                :key="index"
                class="border-b border-[#ECEEF5] px-3"
              >
                <td class="py-4 text-left w-1/4 pl-10  leading-none">{{ item.type }}</td>
                <td class="py-4 text-center w-1/4  leading-none">{{ item.class }}</td>
                <td class="py-4 text-center w-1/4  leading-none">{{ item.host }}</td>
                <td class="py-4 text-center w-1/4  leading-none">
                  {{ item.target ? item.target : item.ip }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="flex justify-center items-center">
            <Button
              id="close-button"
              type="button"
              class="!border-[1.5px] !border-[#3C4549] !py-[1.142rem] !rounded-[0.571rem] hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="close()"
            >
              <template v-slot:label>Close</template>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import eventBus from "../js/event-bus.js"; // Import the event bus
import {mapGetters} from "vuex";

export default {
  props: {
    records: {},
  },
  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
  },
  computed: {
    ...mapGetters(["getDNSRecords", "getCustomDomainsLoader"]),
  },
  methods: {
    close() {
      eventBus.$emit("updateCloseModalEvent");
      this.$bvModal.hide("dns_records_modal");
    },
  },
};
</script>
<style lang="less">
.dns-records-modal {
  .modal-dialog {
    max-width: 77rem !important;
  }

  .modal-content {
    border-radius: 16px !important;
    border-color: #F2F3F8 !important;
  }

  .modal-body {
    padding: 40px 40px 58px 40px !important;
  }
}
</style>
