<template>
  <div class="setting_form h-full">
    <dns-records-modal></dns-records-modal>
    <add-domain-modal v-bind:dns_config="dns_config" ref="add_domain_modal" v-if="isShowModal"></add-domain-modal>

    <div>
      <div class="pb-6 w-full !h-full bg-[#F7F7FC] ">
        <div
          class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-[8px]"
        >
          <div class="flex flex-col justify-between h-full">
            <div class="flex flex-col flex-grow h-full border-[#ECEEF5]">
              <CustomDomainsTableHeader
                :disabledProp="!getDomains.addButtonStatus"
                @OpenParentModal="showModal()"
              ></CustomDomainsTableHeader>
              <CustomDomainsTable
              ></CustomDomainsTable>
            </div>
            <!-- <TeamPaginationV2></TeamPaginationV2>  -->
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex'
import DNSRecordsModal from './dialogs/DNSRecordsModalV2'
import AddDomainModal from './dialogs/AddDomainModalV2'
import DomainsList from './DomainsList'

import GuidedTour from '@/views/guided-tour/GuidedTour.vue'
import {domainTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'

export default ({
  components: {
    GuidedTour,
    'dns-records-modal': DNSRecordsModal,
    'add-domain-modal': AddDomainModal,
    'domains-list': DomainsList,
    CustomDomainsTable: () => import("@/views/pages/setting/domains/CustomDomainsTable.vue"),
    CustomDomainsTableHeader: () => import("@/views/pages/setting/domains/CustomDomainsTableHeader.vue")
  },
  data() {
    return {
      size: '17px',
      color: DARK_PURPLE_LOADER_COLOR,
      loader: true,
      isShowModal: false,
      dns_config: {},
      connectLoader: false
    }
  },
  async created() {
    this.getSiteDetails
    this.dns_config = await this.fetchDNSConfig();
  },
  methods: {
    ...mapActions(['setRequiredReferral', 'setLinksFetchStatus', 'fetchDNSConfig']),
    ...mapMutations([domainTypes.RESET_ADD_DOMAIN]),
    showCustomDomainModal(isEdit, domain) {
      if (!isEdit && this.getSubscription.limits &&
        this.getDomains.items.length >= this.getSubscription.limits.custom_domains_limit) {
        setTimeout(() => {
          this.$bvModal.show('upgrade-subscription-modal')
        }, 100)
        this.alertMessage('You have reached maximum limit of your subscription plan, please upgrade your plan to unlock more features.', 'error')
        return false
      }
      if (isEdit) {
        this.$store.commit(domainTypes.SET_ADD_DOMAIN, JSON.parse(JSON.stringify(domain)))
      } else {
        this.$store.commit(domainTypes.RESET_ADD_DOMAIN, null)
      }
      this.$refs.add_domain_modal.validations = {url: false}
      // $('#customDomainModal').modal('show')

      this.$bvModal.show('customDomainModal')

    },
    showModal(isEdit = false, domain = null) {
      this.isShowModal = true

      setTimeout(() => {
        this.showCustomDomainModal(isEdit, domain)
      }, 100)
    },
    closeModal() {
      this.isShowModal = false
      setTimeout(() => {
        this.isShowModal = true
      }, 100)
    }

  },
  computed: {
    ...mapGetters(['getGuidedTour', 'getProfile', 'getSubscription', 'getDomains'])
  }

})
</script>
