<template>
  <b-modal
    modal-class="modal_basic custom-domain-modal"
    id="customDomainModal"
    centered
    no-close-on-backdrop
    hide-footer
    hide-header
  >
    <div class="w-full h-full">
      <div
        class="w-full h-full flex flex-col items-center justify-end gap-3"
      >
        <div @click="$bvModal.hide('customDomainModal')" class="float-right w-full flex justify-end">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
          </svg>
        </div>

        <div class="flex flex-col items-center justify-center">
          <svg
            width="34"
            height="33"
            viewBox="0 0 34 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32.9529 0.297483C33.3494 0.694106 33.3494 1.33716 32.9529 1.73378L29.5646 5.12208C31.7595 7.93613 31.8617 11.8528 29.8715 14.7696C29.5871 15.1863 29.2601 15.5826 28.8904 15.9524L28.281 16.5617L28.2761 16.5665L27.6491 17.1938C26.8161 18.0266 25.4657 18.0266 24.6329 17.1938L16.0564 8.61727C15.2235 7.78437 15.2235 6.43399 16.0564 5.60109L17.2978 4.35977C20.2557 1.40169 24.9122 1.17702 28.1283 3.68574L31.5164 0.297483C31.9131 -0.0991609 32.5562 -0.0991609 32.9529 0.297483ZM27.4417 5.78381C25.3339 3.68759 22.0883 3.42871 19.6996 5.00711C19.3583 5.2326 19.0345 5.49559 18.7341 5.79607L18.1247 6.40545C17.736 6.79411 17.736 7.42425 18.1247 7.81291L25.4373 15.1254C25.8248 15.5131 26.4529 15.5141 26.8417 15.1283L26.8447 15.1254L27.4541 14.5161C29.8579 12.1122 29.8619 8.2175 27.4665 5.80855L27.4539 5.7963L27.4417 5.78381Z"
              fill="#2560D7"
            />
            <path
              d="M15.6867 13.5005C16.0833 13.8971 16.0833 14.5402 15.6867 14.9368L12.3422 18.2811L14.9684 20.9074L18.3128 17.5629C18.7095 17.1664 19.3526 17.1664 19.7493 17.5629C20.1458 17.9596 20.1458 18.6027 19.7493 18.9994L16.4047 22.3437L17.3118 23.2508C18.4936 24.4326 18.4936 26.3489 17.3118 27.5309L16.7023 28.1403C13.7443 31.0982 9.08786 31.3228 5.87179 28.8142L2.48349 32.2026C2.08687 32.5991 1.44382 32.5991 1.04719 32.2026C0.650549 31.8059 0.650549 31.1628 1.04719 30.7661L4.43551 27.3779C1.92699 24.1618 2.15173 19.5056 5.10973 16.5477L5.7191 15.9382C6.90101 14.7563 8.81726 14.7563 9.99916 15.9382L10.9059 16.8448L14.2504 13.5005C14.647 13.1038 15.29 13.1038 15.6867 13.5005ZM6.557 26.7147C8.96582 29.1118 12.8617 29.1082 15.266 26.704L15.8754 26.0946C16.264 25.7058 16.264 25.0757 15.8754 24.6871L8.56286 17.3746C8.1742 16.9858 7.54407 16.9858 7.15541 17.3746L6.54605 17.984C4.14181 20.3882 4.1381 24.2839 6.5349 26.6928C6.53863 26.6962 6.54233 26.6999 6.54601 26.7036C6.54971 26.7074 6.55336 26.7111 6.557 26.7147Z"
              fill="#2560D7"
            />
          </svg>
          <p
            class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5"
          >
            Connect your custom domain
          </p>

          <p class="pt-1 text-[14px] font-normal font-poppins text-[#757A8A] text-center">
            Please enter details to connect custom domain.
          </p>
        </div>

        <div
          class="flex flex-col justify-center item-center w-full gap-[12px]"
          v-if="!domainLoader"
        >
          <div
            class="input_field"
            v-if="getAddDomain._id && !getAddDomain.connectivity"
          >
            <div class="alert_box_new warning white-shadow ">
              <div class="inner_box align_center border-[2px] !border-[#FFEDCA] !bg-[#FFFEF9]">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <div class="content">
                  <p class="title">DNS not yet verified</p>
                  <p class="text-[12px] font-poppins text-[#757A8A]">
                    DNS propagation can take up-to 24 hours. Please make sure
                    you have pointed the DNS records as mentioned below.
                  </p>
                  <p class="text-[12px] font-poppins text-[#757A8A]">
                    We'll also send you an email once your domain is verified.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex w-full justify-end items-center mt-[1.12rem]">
            <p class="font-poppins text-xs font-normal ">
              <a
                target="_blank"
                class="text-[#2961D2] text-[12px] font-normal font-poppins"
                v-if="!getSiteDetails.isWhiteLabel"
                href="https://docs.replug.io/article/164-custom-domain-setup"
              >How to add DNS records for your custom domain?</a
              >
            </p>
          </div>
          <FloatingLabelInput
            id="Domain_URL"
            @enter="addDomain()"
            :errorType="
              validations.url || validations.validUrl || validations.urlLength
                ? 'danger'
                : ''
            "
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Domain URL"
            placeholder="Your branded domain url. i.e https://track.mywebsite.com"
            v-model="getAddDomain.url"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.url"
                :message="messages.url"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="validations.urlLength"
                :message="messages.urlLength"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="validations.validUrl"
                :message="messages.validUrl"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>

          <FloatingLabelInput
            id="Custom 404 redirection"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="url"
            @enter="addDomain()"
            v-model="getAddDomain.redirect_url"
            placeholder="Enter 404 Redirect URLi.e https://mywebsite.com/404"
            :errorType="
              validations.validRedirectUrl || validations.validRedirectUrlLength
                ? 'danger'
                : ''
            "
            label="Custom 404 redirection"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.validRedirectUrlLength"
                :message="messages.urlLength"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="validations.validRedirectUrl"
                :message="messages.validUrl"
              ></InputFieldMessage>
            </template>
            <template v-slot:right_icon>
              <svg
                v-tooltip="`When a user clicks on the ${ getSiteDetails.agency_name } link that does not exist or
                contains a
                typo, instead of showing an error message the user will be
                redirected to this page e.g https://mywebsite.com/404`"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.46717 1.96704C4.85919 1.96704 1.93433 4.89194 1.93433 8.50001C1.93433 12.1081 4.85919 15.033 8.46717 15.033C12.0751 15.033 15 12.1081 15 8.50001C15 4.89194 12.0751 1.96704 8.46717 1.96704ZM0.934326 8.50001C0.934326 4.33967 4.30689 0.967041 8.46717 0.967041C12.6275 0.967041 16 4.33967 16 8.50001C16 12.6603 12.6275 16.033 8.46717 16.033C4.30689 16.033 0.934326 12.6603 0.934326 8.50001ZM8.46834 7.74146C8.74448 7.74149 8.96832 7.96537 8.96829 8.24151L8.96798 11.7152C8.96795 11.9913 8.74408 12.2152 8.46793 12.2151C8.19179 12.2151 7.96795 11.9912 7.96798 11.7151L7.96829 8.24142C7.96832 7.96528 8.1922 7.74144 8.46834 7.74146ZM8.46798 4.78481C8.19184 4.78481 7.96798 5.00866 7.96798 5.28481C7.96798 5.56095 8.19184 5.78481 8.46798 5.78481H8.47551C8.75166 5.78481 8.97551 5.56095 8.97551 5.28481C8.97551 5.00866 8.75166 4.78481 8.47551 4.78481H8.46798Z"
                  fill="#3C4549"
                />
              </svg>
            </template>
          </FloatingLabelInput>

          <FloatingLabelInput
            id="Branded_domain"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="url"
            label="Branded domain"
            :errorType="
              validations.validBrandUrl || validations.validBrandUrlLength
                ? 'danger'
                : ''
            "
            v-model="getAddDomain.root_redirect"
            placeholder="Your main web page URLi.e https://mywebsite.com"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.validBrandUrlLength"
                :message="messages.urlLength"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="validations.validBrandUrl"
                :message="messages.validUrl"
              ></InputFieldMessage>
            </template>
            <template v-slot:right_icon>
              <svg
                v-tooltip="'Users will be redirected to this URL when they visit the root address of your brand domain'"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.46717 1.96704C4.85919 1.96704 1.93433 4.89194 1.93433 8.50001C1.93433 12.1081 4.85919 15.033 8.46717 15.033C12.0751 15.033 15 12.1081 15 8.50001C15 4.89194 12.0751 1.96704 8.46717 1.96704ZM0.934326 8.50001C0.934326 4.33967 4.30689 0.967041 8.46717 0.967041C12.6275 0.967041 16 4.33967 16 8.50001C16 12.6603 12.6275 16.033 8.46717 16.033C4.30689 16.033 0.934326 12.6603 0.934326 8.50001ZM8.46834 7.74146C8.74448 7.74149 8.96832 7.96537 8.96829 8.24151L8.96798 11.7152C8.96795 11.9913 8.74408 12.2152 8.46793 12.2151C8.19179 12.2151 7.96795 11.9912 7.96798 11.7151L7.96829 8.24142C7.96832 7.96528 8.1922 7.74144 8.46834 7.74146ZM8.46798 4.78481C8.19184 4.78481 7.96798 5.00866 7.96798 5.28481C7.96798 5.56095 8.19184 5.78481 8.46798 5.78481H8.47551C8.75166 5.78481 8.97551 5.56095 8.97551 5.28481C8.97551 5.00866 8.75166 4.78481 8.47551 4.78481H8.46798Z"
                  fill="#3C4549"
                />
              </svg>
            </template>
          </FloatingLabelInput>
        </div>

        <div
          class="flex flex-col justify-start item-center w-full gap-4 "
          v-if="!domainLoader"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="644" height="1" viewBox="0 0 644 1" fill="none">
            <path d="M0 0.5H644" stroke="#D2D5DF"/>
          </svg>
          <!-- end  of para experts only -->
          <div class="w-full ">
            <p
              class="text-[#3C4549] font-poppins pt-[0.5rem] text-[14px] font-semibold leading-5"
            >
              DNS Configuration <span class="text-sm text-[#757A8A]">(Experts Only)* </span>
            </p>

            <p class="pt-2 text-[12px] font-poppins text-[#757A8A] text-left">
              We recommend the following configuration that enables you to use
              variations of your branded domain to create links through {{ getSiteDetails.agency_name }} dashboard.
            </p>
          </div>
          <div class="flex flex-col items-center justify-start">
            <table class=" text-left flex-1 w-full">
              <thead
                :class="'bg-[#F4F6FA]'"
                class="text-[#757A8A] font-semibold text-[0.875rem] font-poppins"
              >
              <tr>
                <td class="py-4 pl-3 w-1/4 leading-none">Record Type</td>
                <td class="py-4 text-left w-1/4 leading-none">Host Name (Or Name)</td>
                <td class="py-4 text-left w-1/4 leading-none">Points to (or Address)</td>
              </tr>
              </thead>

              <tbody
                class="font-poppins text-[#3C4549] text-[0.875rem]"
              >
              <tr
                v-if="!!this.dns_config"
                class="border-b border-[#ECEEF5] px-3"
              >
                <td class="py-4 pl-3 w-1/4 leading-none">A</td>
                <td class="py-4 text-left w-1/4 leading-none">* domain name *</td>
                <td class="py-4 text-left w-1/4 leading-none">
                  {{ this.dns_config.a_address }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>


        </div>
        <div
          class="flex flex-col justify-center items-center mt-3 w-full"
          v-if="domainLoader"
        >
          <clip-loader :size="'26px'" :color="'#1d4ed8'"></clip-loader>
        </div>
        <div class="mt-[2rem] flex gap-x-3 justify-between items-center">
          <Button
            id="login-button"
            type="button"
            class="!border-[1.5px] !border-[#3C4549] !py-[1.142rem]  !text-[14px] !font-semibold !rounded-[0.571rem] hover:bg-[#F2F3F8]"
            buttonClass="btn-lg"
            @click="close()"
          >
            <template v-slot:label>Close</template>
          </Button>

          <Button
            v-if="!domainLoader"
            id="invite-button"
            type="button"
            class="text-white bg-[#2560D7] !py-[1.142rem]  !text-[14px] !font-semibold !rounded-[0.571rem] hover:bg-blue-700"
            buttonClass="btn-lg"
            :disabled="getCustomDomainsLoader.add"
            @click.prevent="addDomain()"
          >
            <template
              v-slot:label
            >{{ getAddDomain._id ? "Update" : "Connect" }}
            </template
            >
            <template v-if="getCustomDomainsLoader.add" v-slot:loader>
              <Loader></Loader>
            </template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {mapGetters} from "vuex";

import validations from "@/mixins/validations";

export default {
  components: {
    FloatingLabelInput: () => import("@/ui/ui-kit/v2/FloatingLabelInput.vue"),
    Selectdropdown: () => import("@/ui/ui-kit/v2/Selectdropdown.vue"),
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    Loader: () => import("@/ui/ui-kit/v2/Loader.vue"),
    Checkbox: () => import("@/ui/ui-kit/v2/Checkbox.vue"),
    InputFieldMessage: () => import("@/ui/ui-kit/v2/InputFieldMessage.vue"),
  },
  props: {
    dns_config: {
      required: true,
    },
  },
  data() {
    return {
      validations: {
        url: false,
        validUrl: false,
        urlLength: false,
        validRedirectUrl: false,
        validRedirectUrlLength: false,
        validBrandUrl: false,
        validBrandUrlLength: false,
      },
      domainLoader: true,
      messages: {
        url: "URL is required.",
        validUrl: "Please enter a valid URL.",
        urlLength: "The URL should not be greater than 500 characters.",
      },
    };
  },
  computed: {
    ...mapGetters(["getAddDomain", "getCustomDomainsLoader"]),
  },
  created() {
    setTimeout(() => {
      this.domainLoader = false;
    }, 1200);
  },
  methods: {
    addDomain() {
      this.validations.url = this.requiredCheck(this.getAddDomain.url);
      this.validations.validUrl = !this.isValidURL(this.getAddDomain.url);

      if (
        this.getAddDomain.redirect_url &&
        this.getAddDomain.redirect_url.length > 0
      ) {
        this.validations.validRedirectUrl = !this.isValidURL(
          this.getAddDomain.redirect_url
        );
      } else {
        this.validations.validRedirectUrl = false;
      }

      if (
        this.getAddDomain.root_redirect &&
        this.getAddDomain.root_redirect.length > 0
      ) {
        this.validations.validBrandUrl = !this.isValidURL(
          this.getAddDomain.root_redirect
        );
      } else {
        this.validations.validBrandUrl = false;
      }

      let isDomainExists = false;
      let blockDomainList = ["replug.link", "rplg.co", "replug.io"];

      blockDomainList.forEach((item) => {
        if (this.getAddDomain.url.includes(item)) {
          isDomainExists = true;
        }
      });

      if (isDomainExists) {
        this.$store.dispatch("toastNotification", {
          message: `Domain url can not be ${this.getAddDomain.url}.`,
          type: "error",
        });
        return;
      }

      let result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      );
      if (result) {
        this.$store.dispatch("storeDomain");
        this.close();
      }
    },
    close() {
      this.$parent.closeModal();
      this.$bvModal.hide("customDomainModal");
    },
  },
  watch: {
    "getAddDomain.url"(value) {
      if (value && value.length > 8) {
        this.validations.url = this.requiredCheck(this.getAddDomain.url);
        this.getAddDomain.url = this.urlConcatenation(this.getAddDomain.url);
        this.validations.urlLength = !this.maxLength(
          this.getAddDomain.url,
          500
        );
        this.validations.validUrl = !this.isValidURL(this.getAddDomain.url);
      }
    },
    "getAddDomain.redirect_url"(value) {
      if (value && value.length > 8) {
        this.getAddDomain.redirect_url = this.urlConcatenation(
          this.getAddDomain.redirect_url
        );
        this.validations.validRedirectUrlLength = !this.maxLength(
          this.getAddDomain.redirect_url,
          500
        );
        this.validations.validRedirectUrl = !this.isValidURL(
          this.getAddDomain.redirect_url
        );
      }
    },
    "getAddDomain.root_redirect"(value) {
      if (value && value.length > 8) {
        this.getAddDomain.root_redirect = this.urlConcatenation(
          this.getAddDomain.root_redirect
        );
        this.validations.validBrandUrlLength = !this.maxLength(
          this.getAddDomain.root_redirect,
          500
        );
        this.validations.validBrandUrl = !this.isValidURL(
          this.getAddDomain.root_redirect
        );
      }
    },
  },
};
</script>
<style lang="less">
.custom-domain-modal {
  .modal-dialog {
    max-width: 51.714rem !important;
  }

  .modal-body {
    padding: 40px 40px 58px 40px !important;
  }
}
</style>

